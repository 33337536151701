<template>
    <div
        class="flex items-center justify-between gap-0.5 text-blacks-100 duration-200 cursor-pointer group hover:bg-blacks-100"
    >
        <nuxt-link
            v-if="!item?.mega && item?.childs.length == 0"
            :to="item?.url ?? '/'"
            :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'hasChild' : ''"
            class="py-3 font-medium flex-1 group-hover:text-white"
        >
            {{ item?.name }}
        </nuxt-link>
        <p v-else @click.self="getItem($event.target)" class="flex-1 py-3 font-medium block group-hover:text-white">
            {{ item?.name }}
        </p>
        <span
            :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'block' : 'hidden'"
            @click.self="getItem($event.target)"
            class="item-close i-ic:twotone-keyboard-arrow-down transform -rotate-90 text-xl block group-hover:text-white"
        ></span>
    </div>
    <ul v-if="!item?.mega && item?.childs && item?.childs.length > 0" class="nav__sub divide-y">
        <MenuBackItem />
        <li
            v-for="(child, index) in item?.childs"
            :key="index"
            class="nav__item flex items-center justify-between gap-0.5 text-blacks-100 duration-200 cursor-pointer font-medium hover:bg-blacks-100 hover:text-white"
        >
            <nuxt-link :to="child?.url ?? '/'" class="py-3 font-medium flex-1 group-hover:text-white">
                {{ child?.name }}
            </nuxt-link>
        </li>
    </ul>
</template>

<script setup lang="ts">
defineProps({
    item: {
        type: Object as any
    }
})
const getItem = (event) => {
    event.parentNode.nextElementSibling?.classList.add('is-active')
}
</script>

<style></style>
